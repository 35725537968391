.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.inputGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.label {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: #010e3f;
  opacity: 1;
}
.error {
  font-family: "Cairo", sans-serif;
  color: var(--primary-color);
}

@media (max-width: 1440px) {
  .label {
    font-size: 18px;
  }
}
@media (max-width: 1024px) {
  .label {
    font-size: 15px;
  }
}
