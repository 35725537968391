.headerContainer {
  display: flex;
  row-gap: 15px;
  column-gap: 5px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  flex-wrap: wrap;
}
@media (max-width: 665px) {
  .headerContainer {
    flex-wrap: wrap;
  }
}
@media (max-width: 425px) {
  .headerContainer {
    margin: 20px 4px;
  }
}
