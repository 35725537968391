.containerEN {
  position: absolute;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.containerAR {
  position: absolute;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loadingPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
  align-items: center;
}

.circle {
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #435f86;
  animation: pulse 1s ease-in-out infinite;
}

.circle:nth-child(1) {
  animation-delay: -0.4s;
}
.circle:nth-child(2) {
  animation-delay: -0.3s;
}
.circle:nth-child(3) {
  animation-delay: -0.2s;
}
.circle:nth-child(4) {
  animation-delay: -0.1s;
}
.circle:nth-child(5) {
  animation-delay: 0s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
